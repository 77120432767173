import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cls from 'classnames';
import { formatArticles } from '../formatters/article';
import { formatDate } from '../utils/date';
import Link from '../components/Link';
import Layout from '../components/Layout';
import Meta from '../components/Meta';
import Section from '../components/Section';
import ContentWithSidebar from '../components/ContentWithSidebar';
import AspectRatioImage from '../components/AspectRatioImage';
import styles from './articles.module.scss';

const ArticlesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { template: { eq: "article" } } }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        nodes {
          ...ArticleFragment
          excerpt(pruneLength: 120)
        }
      }
    }
  `);
  const title = 'Articles';

  return (
    <Layout>
      <Meta title={title} />
      <Section>
        <ContentWithSidebar>
          <h1 className="title">{title}</h1>
          {formatArticles(data).map(article => (
            <div key={article.id} className="box">
              <div className="columns is-variable is-4">
                <div className="column is-one-third">
                  <AspectRatioImage ratio={3 / 2} src={article.image} />
                </div>
                <div className="column is-two-thirds">
                  <div>
                    <div className={styles.heading}>
                      <h2 className={cls('is-size-5', styles.title)}>
                        <Link to={article.path}>{article.title}</Link>
                      </h2>
                      <span className={cls('tag', styles.date)}>
                        {formatDate(article.date)}
                      </span>
                    </div>
                    <p>{article.excerpt}</p>
                    <div>
                      <Link className="button is-small" to={article.path}>
                        Read more →
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </ContentWithSidebar>
      </Section>
    </Layout>
  );
};

export default ArticlesPage;
